import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ListItemText } from '@mui/material';

export default function Dropdown({ options, value, onChange, placeholder, label }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (selectedOption) => {
    onChange(selectedOption);
    setOpen(false);
  };

  return (
    <Box sx={{
      width: 150,
      maxWidth: '100%',
    }}>
      <FormControl fullWidth>
        <InputLabel id="basic-select-label">{label}</InputLabel>
        <Select
            labelId="radio-dropdown-label"
            id="radio-dropdown"
            open={open}
            onClose={handleOpen}
            onOpen={handleOpen}
            value={value}
            fullWidth
            onChange={(e) => handleSelect(e.target.value)}
            displayEmpty
            sx={{
              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
                minHeight: "13px !important",
                lineHeight: "14px !important"
              }
            }}
            renderValue={(selected) => {
            if (selected === '') {
              return selected === '' ? (placeholder || 'ALL') : selected;
            }
            return selected;
          }}
        >
          {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
        </Select>
      </FormControl>
    </Box>
  );
}
