import React, { useEffect, useState } from "react";
import { Box, styled, Typography, Button, Grid } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import AdminImg from "../../../assets/profileimg-h2m.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";
import SettingsIcon from "@mui/icons-material/Settings";
import PasswordIcon from "@mui/icons-material/Password";

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#e5f6fb",
  borderRadius: "10px",
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  textAlign: "center",
  color: "#333",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));

const ProfileImage = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  borderRadius: "50%",
  overflow: "hidden",
  border: `4px solid rgb(0, 168, 215)`,
  marginBottom: theme.spacing(2),
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textAlign: "center",
  flex: 1,
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const SettingContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    marginTop: theme.spacing(0),
  },
}));

const MyAccount = () => {
  const [img, setimg] = useState(null);
  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const [userType, setUserType] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");
      const userData = JSON.parse(sessionStorage.getItem("user"));
      const userId = userData.id;

      try {
        let response = await axios({
          url: ApiConfig.getUser,
          headers: {
            token: `Bearer ${token}`,
          },
          params: {
            _id: userId,
          },
        });

        if (response.status === 200) {
          setimg(response.data.result.image);
          setname(response.data.result.firstName + ' ' + response.data.result.lastName);
          setemail(response.data.result.email);
          setUserType(response.data.result.userType)
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout sectionName="My Account" title="Admin Profile" showTitleOnly={true}>
      <ProfileContainer>
        <ProfileImage>
          <img src={img == null ? AdminImg : img} alt="Admin" />
        </ProfileImage>

        <ProfileDetails>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {name == null ? "--" : name}
          </Typography>
          <Typography>{email == null ? "--" : email}</Typography>
          <Typography sx={{ marginTop: 2 }}>
            As the {userType} of <strong>my-pitch</strong>, I am dedicated to fostering a vibrant community for artists and listeners alike. I manage artist profiles, facilitate communication, and oversee listener engagement, all while continuously striving to improve our platform.
          </Typography>
        </ProfileDetails>
      </ProfileContainer>

      <SettingContainer>
        <Button
          variant="contained"
          style={{
            background: "#fff",
            color: "#2A73E0",
            border: "1px solid #2A73E0",
            padding: "8px 30px",
          }}
          onClick={() => Navigate("/myaccount/editaccount", { state: { name, img, email } })}
        >
          <SettingsIcon style={{ marginRight: "8px" }} /> Edit Profile
        </Button>
        <Button
          variant="contained"
          style={{
            background: "#fff",
            color: "#2C3335",
            border: "1px solid #2C3335",
            padding: "8px 30px",
          }}
          onClick={() => Navigate("/myaccount/changepassword")}
        >
          <PasswordIcon style={{ marginRight: "8px" }} /> Change Password
        </Button>
      </SettingContainer>
    </DashboardLayout>
  );
};

export default MyAccount;
