import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import editIcon from "../../../assets/Edit.png";
import { addUserSchema } from "../../../schema/index";
import DefaultImg from "../../../assets/DefaultImage.png";
import "../../../scss/main.css";
import { postAPIHandler } from "../../../ApiConfig/service";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    paddingTop: "20px",
  },
  editAccountTitle: {},
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    color: "rgb(0, 152, 206)",
  },
  editIcon: {
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};


const EditUser = () => {
  const location = useLocation();
  const userData = location.state?.userData || {};
  const [profileImage, setProfileImage] = useState(
    userData.image || DefaultImg
  );
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: userData.email || "",
    phone: userData.mobileNumber || "",
    username: userData.userName || "",
    userType: userData.userType || "",
    status: userData.status || "PENDING",
  };
  const Navigate = useNavigate();

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });

  const updateProfile = async (values) => {
    try {
      const response = await axios({
        method: "PUT",
        url: ApiConfig.updateProfile,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          userId: userData?._id,
          userName: values.username,
          email: values.email,
          image: profileImage,
          status: values.status,
        },
      });

      if (response?.data?.responseCode === 200) {
        toast.success("Profile updated successfully.");
        Navigate("/UserListing");
      } else {
        toast.error(
          response?.data?.responseMessage || "Failed to update profile."
        );
      }
    } catch (error) {
      console.error("An error occurred while updating the profile:", error);

      // Additional error details
      if (error.response) {
        console.error("Server Error:", error.response.data);
        toast.error(
          error.response.data.responseMessage || "Server error occurred."
        );
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleImageChange = async (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          setProfileImage(res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading image. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        alert("Please select a profile image file.");
      }
    }
  };

  return (
    <DashboardLayout sectionName="User Management" title="Edit Profile">
      <Box sx={accountStyle.editAccountContainer}>
      
        <form style={accountStyle.editAccountContent} onSubmit={handleSubmit}>
          <Box sx={accountStyle.editAccountImgSection}>
            <label
              htmlFor="upload-image"
              className="profile-img-upload-label"
              style={{
                cursor: "pointer",
                display: "inline-block",
                position: "relative",
              }}
            >
              {loading && (
                <CircularProgress size={50} style={accountStyle.loader} />
              )}
              <img
                src={profileImage}
                alt="ProfileImg"
                className="profile-img"
                style={{
                  cursor: "pointer",
                  width: "187px",
                  height: "187px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              {!loading && (
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  style={accountStyle.editIcon}
                />
              )}
              <input
                type="file"
                id="upload-image"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </label>
          </Box>

          <Box sx={accountStyle.editAccountInfoSection}>

            <Box sx={accountStyle.editAccountinfoBox}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Username</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter username"
                />
                {touched.username && errors.username ? (
                  <p style={{ color: "red", float: "left" }}>
                    {errors.username}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box sx={accountStyle.editAccountinfoBox}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Email Address
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter email address"
                />
                {touched.email && errors.email ? (
                  <p style={{ color: "red", float: "left" }}>{errors.email}</p>
                ) : null}
              </Box>
            </Box>

            <Box sx={accountStyle.editAccountinfoBox}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Status</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      value="ACTIVE"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="BLOCKED"
                      control={<Radio />}
                      label="Blocked"
                    />
                    <FormControlLabel
                      value="PENDING"
                      control={<Radio />}
                      label="Pending"
                    />
                  </RadioGroup>
                </FormControl>
                {touched.status && errors.status ? (
                  <p style={{ color: "red", float: "left" }}>{errors.status}</p>
                ) : null}
              </Box>
            </Box>

            <Box sx={accountStyle.editAccountBtnSection}>
              <BasicButtons
                text="Cancel"
                onClick={() => {
                  Navigate("/UserListing");
                }}
              />
              <BasicButtons
                text="Update"
                name="submit"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </form>
      </Box>

      <ToastContainer />
    </DashboardLayout>
  );
};

export default EditUser;
