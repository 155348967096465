import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";
import FilterBox from "../../../components/FilterBox";
import PaginationTable from "../../../components/PaginationTable";
import { Box, CircularProgress } from "@mui/material";
import nodatafound from "../../../assets/datanotfound.png";
import { getAPIHandler } from "../../../ApiConfig/service";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";

const columns = [
  { id: "sr_no", label: "Sr. No." },
  { id: "title", label: "Title" },
  { id: "type", label: "Notification Type" },
  { id: "description", label: "Description"},
  { id: "user_spotify_id", label: "User Spotify ID" },
  { id: "created_date_time", label: "Date & Time" },
];


const ArtistNotification = ({ artistSpotifyId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);


  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllArtistNotification",
        paramsData: { artistSpotifyId },
      });

      const notifications = response.data.result.notifications; 
      const totalPages = response.data.results;
      setData(notifications);
      setTotalPages(totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box className="category-container">
      {loading && (
        <CircularProgress
          size={58}
          style={{ marginLeft: 550, marginTop: 300 }}
        />
      )}

      {!loading && (
        <Box className="table-container">
          {data.length === 0 ? (
            <Box
              className="no-data-found"
              style={{ textAlign: "center", padding: "20px" }}
            >
              <img
                src={nodatafound}
                alt="No Data Found"
                style={{ width: "350px", height: "350px", margin: "25px" }}
              />
            </Box>
          ) : (
            <PaginationTable
              columns={columns}
              data={
                data && Array.isArray(data)
                  ? data.map((row, index) => ({
                      sr_no: index + 1,
                      title: row.title,
                      description : row.body,
                      type: row.notificationType,
                      created_date_time:
                        format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
                          timeZone: "Asia/Kolkata",
                        }) || "-",
                      user_spotify_id: row.userSpotifyId || "-",
                    }))
                  : []
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ArtistNotification;
