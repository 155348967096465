import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, CircularProgress, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import DefaultImg from "../../../assets/DefaultImage.png";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { editAccountSchema } from "../../../schema";
import "../../../scss/main.css";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";
import { toast, ToastContainer } from "react-toastify";
import editIcon from "../../../assets/Edit.png";
import { postAPIHandler } from "../../../ApiConfig/service";

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    paddingTop: "20px",
  },
  editAccountTitle: {},
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    color: "rgb(0, 152, 206)",
  },
  editIcon: {
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};

const EditAccount = () => {
  const location = useLocation();
  const { name, img, email } = location.state || {};
  const [profileImage, setProfileImage] = useState(img || DefaultImg);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    firstName: name?.split(" ")[0] || "", 
    lastName: name?.split(" ").slice(1).join(" ") || "", 
    email: email || "",
  };
  
  const Navigate = useNavigate();

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editAccountSchema,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });

  useEffect(() => {
    setFieldValue("email", email);
  }, [email, setFieldValue]);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const updateProfile = async (values) => {
    try {
      const userData = JSON.parse(sessionStorage.getItem("user"));
      const userId = userData.id;
      const response = await axios({
        method: "PUT",
        url: ApiConfig.updateProfile,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          userId: userId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          image: profileImage,
        },
      });

      if (response && response.data.responseCode === 200) {
        toast.success("Profile updated successfully.");
        const updatedUserData = response.data.result;
        const userDataInStorage = JSON.parse(sessionStorage.getItem("user"));
        userDataInStorage.firstName = updatedUserData.firstName;
        userDataInStorage.lastName = updatedUserData.lastName;
        userDataInStorage.profileImage = updatedUserData.image;

        sessionStorage.setItem("user", JSON.stringify(userDataInStorage));

        Navigate("/myaccount");
      }
    } catch (error) {
      toast.error("An error occurred while updating the profile.");
      console.error("An error occurred while updating the profile:", error);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          setProfileImage(res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading image. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        alert("Please select a profile image file.");
      }
    }
  };

  return (
    <DashboardLayout sectionName="My Account" title="Edit Profile">

      <Box sx={accountStyle.editAccountContainer}>

        <form style={accountStyle.editAccountContent} onSubmit={handleSubmit}>


          <Box sx={accountStyle.editAccountImgSection}>
            <label
              htmlFor="upload-image"
              className="profile-img-upload-label"
              style={{
                cursor: "pointer",
                display: "inline-block",
                position: "relative",
              }}
            >
              {loading && (
                <CircularProgress size={50} style={accountStyle.loader} />
              )}
              <img
                src={profileImage}
                alt="ProfileImg"
                className="profile-img"
                style={{
                  cursor: "pointer",
                  width: "187px",
                  height: "187px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              {!loading && (
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  style={accountStyle.editIcon}
                />
              )}
              <input
                type="file"
                id="upload-image"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </label>
          </Box>



          <Box sx={accountStyle.editAccountInfoSection}>
          
            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>First Name</Typography>
              <InputField
                name="firstName"
                type="text"
                value={values.firstName}
                maxLength={64}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the first name"
                onKeyDown={handleKeyDown}
              />
              {errors.firstName && touched.firstName ? (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.firstName}
                </p>
              ) : null}
            </Box>

            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>Last Name</Typography>
              <InputField
                name="lastName"
                type="text"
                value={values.lastName}
                maxLength={64}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the last name"
              />
              {errors.lastName && touched.lastName ? (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {errors.lastName}
                </p>
              ) : null}
            </Box>

            <Box sx={accountStyle.editAccountinfoBox}>
              <Typography sx={accountStyle.accountLabel}>
                Email Address
              </Typography>
              <InputField
                name="email"
                type="text"
                value={values.email}
                maxLength={64}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the email address"
              />
              {errors.email && touched.email ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {errors.email}
                </p>
              ) : null}
            </Box>
            
            <Box sx={accountStyle.editAccountBtnSection}>
              <BasicButtons
                text="Cancel"
                onClick={() => {
                  Navigate("/myaccount");
                }}
              />
              <BasicButtons
                text="Update"
                name="submit"
                onClick={handleSubmit}
              />
            </Box>

          </Box>


        </form>

      </Box>

      <ToastContainer />

    </DashboardLayout>
  );
};

export default EditAccount;
