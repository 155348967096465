import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import urlimg from "../../../assets/profile_pic.jpeg";
import nodatafound from "../../../assets/datanotfound.png";
import "../../../scss/main.css";
import { getAPIHandler, putAPIHandler } from "../../../ApiConfig/service";
import { RiEyeFill } from "react-icons/ri";
import { BiBlock, BiEdit } from "react-icons/bi";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import BlockPopUp from "../../../components/BlockPopUp";
import toast from "react-hot-toast";

const columns = [
  { id: "sr_no", label: "Sr. No." },
  { id: "thumbnail", label: "Profile Image" },
  { id: "user_name", label: "User Name" },
  { id: "email_address", label: "Email Address" },
  { id: "status", label: "Status" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const dropdownOptionsForSubAdminStatus = [
  "ALL",
  "PENDING",
  "ACTIVE",
  "BLOCKED",
];

const SubAdminListing = () => {
  const [data, setData] = useState([]);
  const [currentUserData, setCurrentUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSubAdminStatus, setSelectedSubAdminStatus] = useState("ALL");
  const [deletePopUp, setDeletePopUp] = useState(false);

  const navigate = useNavigate();

  const handleViewClick = (row) => {
    navigate("/SubAdminlisting/viewsubadmin", {
      state: { userData: row },
    });
  };

  const handleBlock = async () => {
    const payLoadToSend = {
      userId: currentUserData?._id,
      status: currentUserData?.status == "BLOCKED" ? "PENDING" : "BLOCKED",
    };
    try {
      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend: payLoadToSend,
      });

      if (response) {
        fetchSubAdminData();
      }
    } catch (error) {
      console.error("Error toggling status:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleEditClick = (row) => {
    const { _id } = row;
    navigate(`/SubAdminlisting/editsubadmin/${_id}`, {
      state: { userData: row },
    });
  };

  useEffect(() => {
    fetchSubAdminData();
  }, []);

  const fetchSubAdminData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllUserByType",
        paramsData: { userType: "SUBADMIN" },
      });
      const { userData = [], totalPages } = response.data.result;
      setData(userData);
      setFilteredData(userData);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      alert("No data available to download.");
      return;
    }

    const dataWithoutId = filteredData.map((row, index) => ({
      sr_no: index + 1,
      thumbnail: row?.image ? row.image : urlimg, // Using URL instead of JSX
      email_address: row?.email || "-",
      user_name: row?.userName || "-",
      status: row.status || "-",
      created_date_time:
        format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
          timeZone: "Asia/Kolkata",
        }) || "-",
    }));

    const headers = Object.keys(dataWithoutId[0]);

    let csvContent = headers.join(",") + "\n";

    csvContent += dataWithoutId
      .map((row) =>
        headers
          .map((header) => {
            const cell = row[header] || "";
            // Enclose fields in quotes if they contain commas
            return cell.toString().includes(",") ? `"${cell}"` : cell;
          })
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `sub_admin_list_${timestamp}.csv`);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;

    if (filterCriteria.searchText) {
      const searchText = filterCriteria.searchText.toLowerCase().trim();

      filtered = filtered.filter(
        (category) =>
          category.userName.toLowerCase().includes(searchText) ||
          category.email.toLowerCase().includes(searchText)
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M;
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "ALL"
    ) {
      console.log(filterCriteria.selectedValue);
      setSelectedSubAdminStatus(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.status === filterCriteria.selectedValue
      );
    } else {
      setSelectedSubAdminStatus("ALL");
    }

    setFilteredData(filtered);
    console.log("Filtered Data: ", filtered);
  };

  return (
    <DashboardLayout
      sectionName="Sub Admin Management"
      showBackButtonSection={false}
      rightComponent={
        <Box style={{ display: "flex", gap: "10px" }}>
          <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
          <BasicButtons
            text="+ Sub Admin"
            onClick={() => navigate("/SubAdminlisting/addsubadmin")}
          />
        </Box>
      }
    >
      <Box className="category-container">
        <Box className="filter-container">
          <FilterBox
            searchbarPlaceholder={"Search by username/email... "}
            dropdownLabel={"Status"}
            dropdownOptions={dropdownOptionsForSubAdminStatus}
            defaultSelectedValue={"ALL"}
            onFilterChange={handleFilterChange}
          />
        </Box>
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}

        {!loading && (
          <Box className="table-container">
            {filteredData.length === 0 ? (
              <Box
                className="no-data-found"
                style={{ textAlign: "center", padding: "20px" }}
              >
                <img
                  src={nodatafound}
                  alt="No Data Found"
                  style={{ width: "350px", height: "350px", margin: "25px" }}
                />
              </Box>
            ) : (
              <PaginationTable
                columns={columns}
                data={
                  filteredData && Array.isArray(filteredData)
                    ? filteredData.map((row, index) => ({
                        sr_no: index + 1,
                        thumbnail: (
                          <img
                            src={row?.image ? row.image : urlimg}
                            alt="thumbnail"
                            width="65"
                            height="35"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = urlimg;
                            }}
                          />
                        ),
                        email_address: row?.email || "-",
                        user_name: row?.userName || "-",
                        status: row.status || "-",
                        created_date_time:
                          format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
                            timeZone: "Asia/Kolkata",
                          }) || "-",
                        action: (
                          <Box className="row-button-container">
                            <RiEyeFill
                              onClick={() => {
                                handleViewClick(row);
                              }}
                              style={{
                                color: "rgb(0, 168, 215)",
                                fontSize: "24px",
                              }}
                            />
                            <BiBlock
                              onClick={() => {
                                setCurrentUserData(row);
                                setOpen(true);
                              }}
                              style={{
                                color:
                                  row.status === "ACTIVE"
                                    ? "green"
                                    : row.status === "PENDING"
                                    ? "orange"
                                    : "red",
                                fontSize: "24px",
                              }}
                            />
                            <BiEdit
                              onClick={() => {
                                handleEditClick(row);
                              }}
                              style={{
                                color: "rgb(0, 168, 215)",
                                fontSize: "24px",
                              }}
                            />
                          </Box>
                        ),
                      }))
                    : []
                }
              />
            )}
          </Box>
        )}
      </Box>
      <BlockPopUp
        open={open}
        setOpen={setOpen}
        funcToExecute={handleBlock}
        isBlocked={currentUserData?.status == "BLOCKED" ? false : true}
      />
    </DashboardLayout>
  );
};

export default SubAdminListing;
