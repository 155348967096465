import * as Yup from "yup";

const emailRegex =/^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&^(}){|\-_+="':;,.~`])[A-Za-z\d@$!#%*?&^(}){|\-_+="':;,.~`]{8,}$/;
const firstnameRegex = /^[a-zA-Z]*$/;
const lastRegex = /^[a-zA-Z\s]*$/;

export const loginSchema = Yup.object({
    email: Yup.string()
      .required("Please enter the email address.")
      .trim()
      .matches(emailRegex, "Please enter valid email address.")
      .max(64, "Email cannot be more than 64 characters.")
      .email("Invalid email address."),
  
    password: Yup.string()
          .required('Please enter the password.')
          .trim()
          .min(8,'Must be more than 8 characters.')
          .max(16,'Password cannot be more than 16 characters.'),
});

export const forgotPasswordSchema = Yup.object({
    email: Yup.string()
        .required("Please enter the email address.")
        .trim()
        .matches(emailRegex, "Please enter valid email address.")
        .max(64, "Email cannot be more than 64 characters.")
        .email("Invalid email address."),
});

export const resetPasswordSchema = Yup.object({
    newPassword: Yup.string()
          .required('Please enter the new password.')
          .trim()
          .min(8,'Must be more than 8 characters.')
          .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
          .max(16,'Password cannot be more than 16 characters.'),

    confirmPassword: Yup.string()
        .required("Please enter the confirn password.")
        .trim()
        .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const changePasswordSchema = Yup.object({
    oldPassword: Yup.string()
          .required('Please enter the old password.')
          .trim()
          .min(8,'Must be more than 8 characters.')
          .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
          .max(16,'Password cannot be more than 16 characters.'),

    newPassword: Yup.string()
          .required('Please enter the new password.')
          .trim()
          .min(8,'Must be more than 8 characters.')
          .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
          .max(16,'Password cannot be more than 16 characters.')
          .test(
            "not-same-as-old-password",
            "New password must be different from the old password",
            function (value) {
                return value !== this.parent.oldPassword;
            }
        ),

    confirmPassword: Yup.string()
        .required("Please enter the confirn password.")
        .trim()
        .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const editAccountSchema = Yup.object({
    firstName: Yup.string()
        .required('Please enter your first name.')
        .trim()
        .min(2,'First name cannot be less than 2 characters.')
        .max(20,'First name cannot be more than 20 characters.')
        .matches(firstnameRegex, 'Invalid first name.'),

    lastName: Yup.string()
        .required('Please enter your last name.')
        .trim()
        .min(2,'Last name cannot be less than 2 characters.')
        .max(20,'Last name cannot be more than 20 characters.')
        .matches(lastRegex, 'Invalid last name.'),
        
    email: Yup.string()
        .required("Please enter the email address.")
        .trim()
        .matches(emailRegex, "Please enter valid email address.")
        .max(64, "Email cannot be more than 64 characters.")
        .email("Invalid email address."),
});

export const addUserSchema = Yup.object({
    email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .matches(emailRegex, "Please enter valid email address.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
    username: Yup.string()
        .required('Required'),
    userType: Yup.string()
        .required('Required'),
    status: Yup.string()
        .required('Required'),
});
