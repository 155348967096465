import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import {
  Dashboard,
  Category,
  AccountCircle,
  ExitToApp,
  Feedback,
  Book,
} from "@mui/icons-material";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useLocation, useNavigate } from "react-router-dom";
import "../scss/main.css";
import profileImg from "../assets/profileimg-h2m.png";

const Sidebar = ({ onLogoutClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userData = JSON.parse(sessionStorage.getItem("user"));

  const { firstName, lastName, profileImage } = userData || {};

  const getListItemClass = (path) => {
    return location.pathname === path
      ? "sidebarListItem active"
      : "sidebarListItem";
  };

  const getPermissionsFromCookie = () => {
    const permissionsCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("permissions="));

    if (!permissionsCookie) return null;

    const permissionsString = permissionsCookie.split("=")[1];

    try {
      return permissionsString
        ? JSON.parse(decodeURIComponent(permissionsString))
        : null;
    } catch (error) {
      console.error("Error parsing permissions cookie:", error);
      return null;
    }
  };

  const permissions = getPermissionsFromCookie();

  // Helper function to check if user has permission for a specific route
  const hasPermission = (route) => {
    if (permissions && permissions.allPermissions) return true;
    return (
      permissions?.permissions.some((p) => p.route === route && p.granted) ||
      route === "/myaccount" // Assuming /profile is always accessible
    );
  };

  return (
    <Box
      className="sidebarContainer"
      sx={{
        "& .MuiListItem-root": {
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <List>
        <ListItem
          className="sidebarListItem"
          sx={{
            background: "rgb(0, 168, 215)",
            marginBottom: "10px",
            borderRadius: "0.5rem",
          }}
          onClick={() => navigate("")}
        >
          <Box>
            <Avatar
              style={{ height: "8rem", width: "8rem" }}
              src={profileImage == null ? profileImg : profileImage}
            />
            <ListItemText
              className="sidebarAdminName"
              sx={{ color: "white" }}
              primary={firstName && lastName ? `${firstName} ${lastName}` : "--"}
            />
          </Box>
        </ListItem>

        {hasPermission("/dashboard") && (
          <ListItem
            className={getListItemClass("/dashboard")}
            onClick={() => navigate("/dashboard")}
          >
            <ListItemIcon className="sidebarIcon">
              <Dashboard />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Dashboard" />
          </ListItem>
        )}

        {hasPermission("/SubAdminlisting") && (
          <ListItem
            className={getListItemClass("/SubAdminlisting")}
            onClick={() => navigate("/SubAdminlisting")}
          >
            <ListItemIcon className="sidebarIcon">
              <Category />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Sub Admin Management" />
          </ListItem>
        )}

        {hasPermission("/Userlisting") && (
          <ListItem
            className={getListItemClass("/Userlisting")}
            onClick={() => navigate("/Userlisting")}
          >
            <ListItemIcon className="sidebarIcon">
              <Category />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="User Management" />
          </ListItem>
        )}

        {hasPermission("/ArtistListing") && (
          <ListItem
            className={getListItemClass("/ArtistListing")}
            onClick={() => navigate("/ArtistListing")}
          >
            <ListItemIcon className="sidebarIcon">
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Revenue Management" />
          </ListItem>
        )}

        {hasPermission("/permissionListing") && (
          <ListItem
            className={getListItemClass("/permissionListing")}
            onClick={() => navigate("/permissionListing")}
          >
            <ListItemIcon className="sidebarIcon">
              <PermDataSettingIcon />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Permission Management" />
          </ListItem>
        )}

        {hasPermission("/FeedbackListing") && (
          <ListItem
            className={getListItemClass("/FeedbackListing")}
            onClick={() => navigate("/FeedbackListing")}
          >
            <ListItemIcon className="sidebarIcon">
              <Feedback />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Application Feedback" />
          </ListItem>
        )}

        {hasPermission("/staticcontent") && (
          <ListItem
            className={getListItemClass("/staticcontent")}
            onClick={() => navigate("/staticcontent")}
          >
            <ListItemIcon className="sidebarIcon">
              <Book />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="Static Content Management" />
          </ListItem>
        )}

        {hasPermission("/myaccount") && (
          <ListItem
            className={getListItemClass("/myaccount")}
            onClick={() => navigate("/myaccount")}
          >
            <ListItemIcon className="sidebarIcon">
              <AccountCircle />
            </ListItemIcon>
            <ListItemText className="sidebarItemText" primary="My Account" />
          </ListItem>
        )}

        <ListItem className="sidebarListItem" onClick={onLogoutClick}>
          <ListItemIcon className="sidebarIcon">
            <ExitToApp />
          </ListItemIcon>
          <ListItemText className="sidebarItemText" primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;
