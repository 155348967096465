import React, { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import typographyStyles from "../../../theme/Typography";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { postAPIHandler } from "../../../ApiConfig/service/index";
import toast from "react-hot-toast";
import BasicButtons from "../../../components/Button";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

// const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
//   "& .form-control": {
//     color: "#000",
//     borderRadius: "15px",
//     height: "50px",
//     background: "#F2F2F2 !important",
//     border: "1px solid #DFDFDF !important",
//   },
//   "& .selected-flag:hover, .selected-flag:focus": {
//     backgroundColor: "transparent !important",
//   },
//   "& .selected-flag": {
//     border: "1px solid #DFDFDF !important",
//     borderRadius: "10px",
//     background: "#F2F2F2 !important",
//     "&:hover": {
//       backgroundColor: "none",
//     },
//   },
//   "& .selected-flag .arrow": {
//     left: "20px",
//   },
//   "& .flag-dropdown": {
//     backgroundColor: "transparent",
//     border: "1px solid #383232",
//     borderRadius: "5px 0 0 5px",
//   },
//   "& .flag-dropdown.open .selected-flag": {
//     background: "#1C1C1C",
//   },
//   "& .country-list .country": {
//     padding: "7px 9px",
//     textAlign: "left",
//     backgroundColor: "#fff",
//     color: "#000",
//     "&:hover": {
//       background: "#681E65 !important",
//       color: "#fff",
//     },
//   },
//   "& .country-list .country.highlight": {
//     backgroundColor: "#F39200",
//   },
//   "& .country-list": {
//     color: "#000",
//   },
// }));
const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditHeading: {
    ...typographyStyles.heading,
  },
  radioContainer: {
    display: "flex",
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "5px",
    marginLeft: "0px",
  },
};

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
  },
  editAccountTitle: {},
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    color: "rgb(0, 152, 206)",
  },
  editIcon: {
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(0, 168, 215)",
    color: "white",
    flex: "1 1 auto",
    padding: 8,
  },

  tableContainer: {
    overflowX: "auto",
  },

  tableheaderCell: {
    backgroundColor: "rgb(0, 168, 215)",
    color: "#fff",
    fontSize: "16px",
    padding: "4px 8px",
    fontWeight: 500,
    height: 40,
  },

  tableCell: {
    flex: "1 1 auto",
    padding: "4px 8px",
    fontSize: "14px",
  },
};

const AddSubAdmin = () => {
  const permissions = [
    { label: "Dashboard", route: "/dashboard" },
    { label: "User Management", route: "/Userlisting" },
    { label: "Revenue Management", route: "/ArtistListing" },
    { label: "Permission Management", route: "/permissionListing" },
    { label: "Application Feedback", route: "/FeedbackListing" },
    { label: "Static Content Management", route: "/staticcontent" },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/[a-zA-Z]/, "First name must contain at least one letter.")
      .min(3, "First name must be at least 3 characters.")
      .max(80, "First name must be 80 characters or less.")
      .required("Please enter first name."),
    lastName: Yup.string()
      .matches(/[a-zA-Z]/, "Last name must contain at least one letter.")
      .min(3, "Last name must be at least 3 characters.")
      .max(80, "Last name must be 80 characters or less.")
      .required("Please enter last name."),
    email: Yup.string()
      .email("Invalid email format.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format."
      )
      .required("Please enter email."),
    // phoneNumber: Yup.string()
    //   .required(
    //     "A valid account phone number is required, including the country code."
    //   )
    //   .test(
    //     "is-valid-phone",
    //     "Please enter a valid phone number.",
    //     function (value) {
    //       console.log(value, selectedCountry);
    //       if (typeof value !== "string") return false;
    //       const phoneNumber = parsePhoneNumberFromString(
    //         value,
    //         selectedCountry
    //       );
    //       return phoneNumber ? phoneNumber.isValid() : false;
    //     }
    //   )
    //   .max(
    //     20,
    //     "A valid account phone number is required, including the country code."
    //   ),

    imageUrl: Yup.string(),
  });

  const handleImageUpload = async (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });
          setImageUrl(res.data.result);
          setFieldValue("imageUrl", res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading image. Please try again.");
        }
      }
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const transformedPermissions = Object.keys(values.permissions).map(
      (label) => {
        const route =
          permissions.find((perm) => perm.label === label)?.route || "";
        return { label, route, granted: values.permissions[label] };
      }
    );
    const hasGrantedPermissions = transformedPermissions.some(
      (perm) => perm.granted
    );

    if (!hasGrantedPermissions) {
      toast.error("Please grant at least one permission.");
      setLoading(false);
      return;
    }

    try {
      const res = await postAPIHandler({
        endPoint: "createSubadmin",
        dataToSend: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          image: values.imageUrl,
          permissions: transformedPermissions,
        },
      });
      console.log(res);
      if (res.data.responseCode == 200) {
        setLoading(false);
        toast.success(res?.data?.responseMessage);
        navigate("/SubAdminlisting");
        resetForm();
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log("Error while creating subadmin", error);
      toast.success(error.responseMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout sectionName="User Management" title="Add Sub-Admin">
      <Paper>
        <Box sx={{ marginTop: 2 }}>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              permissions: permissions.reduce((acc, { label }) => {
                acc[label] = false;
                return acc;
              }, {}),
              imageUrl: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleBlur,
              handleChange,
            }) => (
              <Form style={accountStyle.editAccountContent}>
                <Box
                  sx={{
                    padding: 2,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    marginBottom: 4,
                  }}
                >
                  <Typography sx={addEditStyle.addEditHeading} gutterBottom>
                    Basic Details
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          src={imageUrl || ""}
                          style={{ width: 150, height: 150 }}
                        />
                        {loading && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: "calc(50% - 20px)",
                          }}
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(event) =>
                              handleImageUpload(event, setFieldValue)
                            }
                          />
                          <PhotoCamera />
                        </IconButton>
                      </Box>
                      <ErrorMessage
                        name="imageUrl"
                        component="div"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Typography sx={addEditStyle.addEditLabel}>
                        First Name
                      </Typography>
                      <Field
                        as={TextField}
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        placeholder="Enter First Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: false }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px", // Custom border radius
                            backgroundColor: "#f9f9f9", // Light gray background
                          },
                          "& .MuiFormHelperText-root": {
                            fontSize: "14px", // Smaller font size for helper text
                            marginLeft: "0px", // Margin between input field and error text
                          },
                        }}
                        helperText={
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            style={addEditStyle.errorText}
                          />
                        }
                        inputProps={{ maxLength: 50 }}
                        validate={(value) => {
                          const regex = /\s{2,}/;
                          if (regex.test(value)) {
                            return "First Name cannot contain more than one consecutive space";
                          }
                          return undefined;
                        }}
                      />

                      <Typography sx={addEditStyle.addEditLabel}>
                        Last Name
                      </Typography>
                      <Field
                        as={TextField}
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        placeholder="Enter Last Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: false }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px", // Custom border radius
                            backgroundColor: "#f9f9f9", // Light gray background
                          },
                          "& .MuiFormHelperText-root": {
                            fontSize: "14px", // Smaller font size for helper text
                            marginLeft: "0px", // Margin between input field and error text
                          },
                        }}
                        helperText={
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            style={addEditStyle.errorText}
                          />
                        }
                        inputProps={{ maxLength: 50 }}
                        validate={(value) => {
                          const regex = /\s{2,}/;
                          if (regex.test(value)) {
                            return "Full Name cannot contain more than one consecutive space";
                          }
                          return undefined;
                        }}
                      />

                      <Typography sx={addEditStyle.addEditLabel}>
                        Email Address
                      </Typography>
                      <Field
                        as={TextField}
                        name="email"
                        variant="outlined"
                        fullWidth
                        value={values.email.toLocaleLowerCase()}
                        margin="normal"
                        placeholder="Enter Email Address"
                        InputLabelProps={{ shrink: false }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px", // Custom border radius
                            backgroundColor: "#f9f9f9", // Light gray background
                          },
                          "& .MuiFormHelperText-root": {
                            fontSize: "14px", // Smaller font size for helper text
                            marginLeft: "0px", // Margin between input field and error text
                          },
                        }}
                        helperText={
                          <ErrorMessage
                            name="email"
                            component="div"
                            style={addEditStyle.errorText}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    marginBottom: 4,
                  }}
                >
                  <Typography sx={addEditStyle.addEditHeading} gutterBottom>
                    Permissions
                  </Typography>
                  <TableContainer sx={PaginationTableStyle.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={PaginationTableStyle.tableheaderCell}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            style={PaginationTableStyle.tableheaderCell}
                          >
                            Permission
                          </TableCell>
                          <TableCell
                            style={PaginationTableStyle.tableheaderCell}
                          >
                            Operations
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissions.map((perm, index) => (
                          <TableRow key={perm.label}>
                            <TableCell style={PaginationTableStyle.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={PaginationTableStyle.tableCell}>
                              {perm.label}
                            </TableCell>
                            <TableCell style={PaginationTableStyle.tableCell}>
                              <Checkbox
                                checked={
                                  values.permissions[perm.label] || false
                                }
                                onChange={() => {
                                  const newValue =
                                    !values.permissions[perm.label];
                                  setFieldValue(
                                    `permissions.${perm.label}`,
                                    newValue
                                  );
                                  console.log(
                                    "Permission toggled:",
                                    perm.label,
                                    perm.route
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    px:30
                  }}
                >
                  <BasicButtons type="submit" text="Submit" loading={loading} />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </DashboardLayout>
  );
};

export default AddSubAdmin;
