import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box } from "@mui/material";
import DashboardCard from "../../../components/Cards/DashboardCard";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import FeedbackIcon from '@mui/icons-material/Feedback';
import PendingIcon from '@mui/icons-material/Pending';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { getAPIHandler } from "../../../ApiConfig/service";
import { useNavigate } from "react-router-dom";

const dashboardStyle = {
  dashboardContainer: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  cardSection: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2%",
  },
  cardBox: {
    margin: "10px 0px",
    minWidth: "32%",
  },
};

const Dashboard = () => {
  const [totalArtists, setTotalArtists] = useState(null);
  const [totalListeners, setTotalListeners] = useState(null);
  const [totalFeedBacks, setTotalFeedBacks] = useState(null);
  const [totalPermissions, setTotalPermissions] = useState(null);
  const [totalRespondedFeedBacks, setTotalRespondedFeedBacks] = useState(null);
  const [totalPendingFeedBacks, setTotalPendingFeedBacks] = useState(null);
  const [totalPendingPermissions, setTotalPendingPermissions] = useState(null);
  const [totalgrantedPermissions, setTotalGrantedPermissions] = useState(null);
  const [totaldenyPermissions, setTotalDenyPermissions] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => navigate('/Userlisting');
  const handleFeedBackClick = () => navigate('/FeedbackListing');
  const handlePermissionClick = () => navigate('/permissionListing');

  const getPermissionsFromCookie = () => {
    const permissionsCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("permissions="));

    if (!permissionsCookie) return null;

    const permissionsString = permissionsCookie.split("=")[1];
    try {
      return permissionsString
        ? JSON.parse(decodeURIComponent(permissionsString))
        : null;
    } catch (error) {
      console.error("Error parsing permissions cookie:", error);
      return null;
    }
  };


  const checkPermission = (permission) => {
    if (permissions?.allPermissions) {
      return true;  // Admin has all permissions
    }
  
    // For subadmins or users, check if the specific permission is granted
    return permissions?.permissions.some((p) => p.label === permission && p.granted);
  };
  

  const fetchArtistData = async () => {
    const response = await getAPIHandler({
      endPoint: "getAllUserByType",
      paramsData: { userType: "ARTIST" },
    });

    if (response && response.data && response.data.result && response.data.result.userData) {
      setTotalArtists(response.data.result.userData.length);
    }
  };

  const fetchListenerData = async () => {
    const response = await getAPIHandler({
      endPoint: "getAllUserByType",
      paramsData: { userType: "USER" },
    });

    if (response && response.data && response.data.result && response.data.result.userData) {
      setTotalListeners(response.data.result.userData.length);
    }
  };

  const fetchFeedbackData = async () => {
    const response = await getAPIHandler({
      endPoint: "getAllUserFeedback"
    });

    if (response && response.data && response.data.result && response.data.result.feedbackData) {
      const feedbackData = response.data.result.feedbackData;
      setTotalFeedBacks(feedbackData.length);
      const respondedFeedbacks = feedbackData.filter(feedback => feedback.replySent).length;
      const pendingFeedbacks = feedbackData.length - respondedFeedbacks;

      setTotalRespondedFeedBacks(respondedFeedbacks);
      setTotalPendingFeedBacks(pendingFeedbacks);
    }
  };

  const fetchPermissionData = async () => {
    const response = await getAPIHandler({ endPoint: "getAllPermissions" });

    if (response?.data?.result?.permissionData) {
      const permissions = response.data.result.permissionData;
      setTotalPermissions(permissions.length);

      const grantedPermissions = permissions.filter(item => item.permission === "GRANTED").length;
      const pendingPermissions = permissions.filter(item => item.permission === "PENDING").length;
      const deniedPermissions = permissions.filter(item => item.permission === "DENY").length;

      setTotalGrantedPermissions(grantedPermissions);
      setTotalPendingPermissions(pendingPermissions);
      setTotalDenyPermissions(deniedPermissions);
    }
  };

  useEffect(() => {
    const permissions = getPermissionsFromCookie(); // Fetch permissions on component mount
    console.log(permissions); // Log permissions for debugging
    setPermissions(permissions);
    fetchArtistData();
    fetchListenerData();
    fetchFeedbackData();
    fetchPermissionData();
  }, []);
  

  const cardData = [
    // "User Management" Cards
    ...(checkPermission("User Management") ? [
      {
        cardIcon: InterpreterModeIcon,
        title: "Total Artists",
        count: totalArtists !== null ? totalArtists : "0",
        onClick: handleClick
      },
      {
        cardIcon: HeadphonesIcon,
        title: "Total Listeners",
        count: totalListeners !== null ? totalListeners : "0",
        onClick: handleClick
      }
    ] : []),

    // "Feedback Management" Cards
    ...(checkPermission("Application Feedback") ? [
      {
        cardIcon: FeedbackIcon,
        title: "Total Feedbacks",
        count: totalFeedBacks !== null ? totalFeedBacks : "0",
        onClick: handleFeedBackClick
      },
      {
        cardIcon: MarkChatReadIcon,
        title: "Resolved Feedbacks",
        count: totalRespondedFeedBacks !== null ? totalRespondedFeedBacks : "0",
        onClick: handleFeedBackClick
      },
      {
        cardIcon: PendingIcon,
        title: "Pending Feedbacks",
        count: totalPendingFeedBacks !== null ? totalPendingFeedBacks : "0",
        onClick: handleFeedBackClick
      }
    ] : []),

    // "Permission Management" Cards
    ...(checkPermission("Permission Management") ? [
      {
        cardIcon: PermDataSettingIcon,
        title: "Total Permissions",
        count: totalPermissions !== null ? totalPermissions : "0",
        onClick: handlePermissionClick
      },
      {
        cardIcon: PendingIcon,
        title: "Pending Permissions",
        count: totalPendingPermissions !== null ? totalPendingPermissions : "0",
        onClick: handlePermissionClick
      },
      {
        cardIcon: CheckCircleOutlineIcon,
        title: "Granted Permissions",
        count: totalgrantedPermissions !== null ? totalgrantedPermissions : "0",
        onClick: handlePermissionClick
      },
      {
        cardIcon: DoDisturbAltOutlinedIcon,
        title: "Deny Permissions",
        count: totaldenyPermissions !== null ? totaldenyPermissions : "0",
        onClick: handlePermissionClick
      }
    ] : [])
  ];

  return (
    <DashboardLayout sectionName="Dashboard" showBackButtonSection={false}>
      <Box sx={dashboardStyle.dashboardContainer}>
        <Box sx={dashboardStyle.cardSection}>
          {cardData.map((card, index) => (
            <Box key={index} sx={dashboardStyle.cardBox}>
              <DashboardCard
                cardIcon={card.cardIcon}
                title={card.title}
                count={card.count}
                onClick={card.onClick}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
