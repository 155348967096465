import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import "chart.js/auto";
import { getAPIHandler } from "../../../ApiConfig/service";
import axios from "axios";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  styled,
  Typography,
  CircularProgress,
  Link,
  TextField,
  Grid,
} from "@mui/material";
import { FaExternalLinkAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#e5f6fb",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  textAlign: "center",
  color: "#333",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));

const ProfileImage = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  borderRadius: "50%",
  overflow: "hidden",
  border: `4px solid rgb(0, 168, 215)`,
  marginBottom: theme.spacing(2),
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const FollowButton = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  backgroundColor: "transparent",
  color: "#333",
  borderRadius: theme.shape.borderRadius,
  textAlign: "start",
  cursor: "pointer",
  transition: "background-color 0.3s",
}));

const DateRangeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "50px",
  padding: theme.spacing(1),
  boxShadow: theme.shadows[2],
  width: "100%",
  maxWidth: "600px",
  marginBottom: theme.spacing(3),
}));

const DateInput = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    backgroundColor: "#e5f6fb",
    height: "45px",
    color: "#333",
    paddingLeft: theme.spacing(2),
    "& input::placeholder": {
      color: "#333",
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: "#e5f6fb",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(3),
  overflow: "hidden",
}));

const ChartTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const LineChart = styled(Box)(({ theme }) => ({
  "& .apexcharts-title-text": {
    color: "white",
  },
}));

const ArtistListenersGraph = () => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [artist, setArtist] = useState(null);
  const [accessToken, setAccessToken] = useState("");
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [artistUUID, setArtistUUID] = useState("");
  const { id: rawSpotifyId } = useParams();
  const id = rawSpotifyId.replace("spotify:artist:", "");

  const platform = "spotify";
  const MAX_DAYS = 90;

  const formatFollowers = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getToken = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getSpotifyToken",
      });

      if (response && response.data && response.data.accessToken) {
        setAccessToken(response.data.accessToken);
        return response.data.accessToken;
      } else {
        throw new Error("Access token not found in API response");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  const fetchArtistUUID = async (id) => {
    const url = `https://customer.api.soundcharts.com/api/v2.9/artist/by-platform/spotify/${id}`;
    const headers = {
      "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
      "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data && response.data.object && response.data.object.uuid) {
        const uuid = response.data.object.uuid;
        console.log(uuid, "gfghfhgfhjghghfghgh");
        setArtistUUID(response.data.object.uuid);
      } else {
        throw new Error("UUID not found in response data");
      }
    } catch (error) {
      console.error("Error fetching UUID:", error);
      setError("Failed to fetch artist UUID");
    }
  };

  const API_URL = `https://customer.api.soundcharts.com/api/v2/artist/${artistUUID}/streaming/${platform}/listening`;

  const fetchData = async (start, end) => {
    try {
      const headers = {
        "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
        "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
      };

      const response = await fetch(
        `${API_URL}?startDate=${start}&endDate=${end}`,
        {
          method: "GET",
          headers,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      return data.items || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      return [];
    }
  };

  const fetchArtistData = async (id, accessToken) => {
    try {
      const artistResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (artistResponse?.data) {
        setArtist(artistResponse.data);
      } else {
        console.error("Artist data not found in response");
        setError("No artist data found");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching artist data:", error);
      setError("Failed to fetch artist data");
      setLoading(false);
    }
  };

  const splitDateRange = (start, end) => {
    const ranges = [];
    let currentStart = dayjs(start);
    const finalEnd = dayjs(end);

    while (currentStart.isBefore(finalEnd)) {
      // Use dayjs to get the smaller (earliest) of the two dates
      const currentEnd = currentStart.add(MAX_DAYS - 1, "day");

      // Ensure that currentEnd doesn't go beyond finalEnd
      const rangeEnd = currentEnd.isAfter(finalEnd) ? finalEnd : currentEnd;

      ranges.push({
        start: currentStart.format("YYYY-MM-DD"),
        end: rangeEnd.format("YYYY-MM-DD"),
      });

      // Move currentStart to the next day after currentEnd
      currentStart = rangeEnd.add(1, "day");
    }

    return ranges;
  };

  const getMonthlyData = (data) => {
    const monthlyData = {};

    data.forEach((item) => {
      const month = dayjs(item.date).format("YYYY-MM");
      if (!monthlyData[month]) {
        monthlyData[month] = 0;
      }
      monthlyData[month] += item.value;
    });

    return Object.entries(monthlyData).map(([month, value]) => ({
      month,
      value,
    }));
  };

  const calculateRevenue = (totalListeners) => {
    const discountRate = 0.1;
    const startYear = 2024;
    const endYear = 2029;
    const duration = endYear - startYear + 1;

    const estimatedCashFlows = [];
    let totalRevenue = 0;
    for (let i = 0; i < duration; i++) {
      const estimatedCashFlow = totalListeners * Math.pow(1 + discountRate, i);
      estimatedCashFlows.push(estimatedCashFlow);
      totalRevenue += estimatedCashFlow;
    }

    let fairPrice = 0;
    for (let i = 0; i < duration; i++) {
      fairPrice += estimatedCashFlows[i] / Math.pow(1 + discountRate, i + 1);
    }

    return fairPrice.toFixed(2);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      setError(null);
      // if (dayjs(startDate).isAfter(dayjs(endDate))) {
      //   setError(
      //     "Invalid date range: Start date cannot be later than end date."
      //   );
      //   toast.error(
      //     "Invalid date range: Start date cannot be later than end date."
      //   );
      //   setLoading(false);
      //   return;
      // }

      if (!accessToken) await getToken();
      if (!artistUUID) await fetchArtistUUID(id);

      const dateRanges = splitDateRange(startDate, endDate);
      const allData = [];

      for (const range of dateRanges) {
        const data = await fetchData(range.start, range.end);
        allData.push(...data);
      }

      const monthlyData = getMonthlyData(allData);
      const revenueData = monthlyData.map((item) => ({
        ...item,
        revenue: calculateRevenue(item.value),
      }));

      setGraphData({ monthlyData, revenueData });
    } catch (error) {
      console.error("Error loading data:", error);
      setError("Failed to load data");
      toast.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        const token = await getToken();
        if (id) {
          await fetchArtistUUID(id);
          await fetchArtistData(id, token);
        } else {
          setError(new Error("Invalid Spotify ID format"));
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchDetails();
  }, [id]);

  useEffect(() => {
    if (artistUUID) {
      loadData();
    }
  }, [artistUUID, startDate, endDate]);

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const data = {
    labels: graphData.monthlyData?.map((item) => item.month),
    datasets: [
      {
        label: "Total Listeners",
        data: graphData.monthlyData?.map((item) => item.value),
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.4,
      },
      {
        label: "Artist Revenue",
        data: graphData.revenueData?.map((item) => item.revenue),
        fill: false,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.4,
      },
    ],
  };

  const listenersData = {
    labels: graphData.monthlyData?.map((item) => item.month),
    datasets: [
      {
        label: "Total Listeners",
        data: graphData.monthlyData?.map((item) => item.value),
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${context.raw.toLocaleString()}`,
        },
      },
    },
    scales: {
      x: { title: { display: true, text: "Month" } },
      y: { title: { display: true, text: "Total Listeners / Revenue($)" } },
    },
  };

  const totalListeners = graphData?.monthlyData
    ? graphData.monthlyData.reduce((sum, item) => sum + item.value, 0)
    : 0;

  const totalRevenueEstimates = graphData?.revenueData
    ? graphData.revenueData
        .reduce((sum, item) => sum + parseFloat(item.revenue), 0)
        .toFixed(2)
    : "0.00";

  return (
    <DashboardLayout sectionName="Revenue Management" title="Artist Revenue">
      <Box className="view-user-container">
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}
        {!loading && !error && (
          <Box className="view-user-content">
            <ProfileContainer>
              <ProfileImage>
                {artist && artist.images?.length > 0 ? (
                  <img
                    src={artist.images[1]?.url || artist.images[0]?.url}
                    alt={truncateText(artist.name, 25) || "Artist"}
                  />
                ) : (
                  <Typography>No Image Available</Typography>
                )}
              </ProfileImage>

              <ProfileDetails>
                {artist ? (
                  <>
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                      {truncateText(artist.name, 25) ||
                        "Artist Name Not Available"}
                    </Typography>
                    <Typography>
                      Followers:{" "}
                      {formatFollowers(artist?.followers?.total) || "N/A"}
                    </Typography>
                    <Typography>
                      Popularity: {artist?.popularity ?? "N/A"}
                    </Typography>
                    <Typography>
                      Total Listeners:{" "}
                      {totalListeners.toLocaleString() || "N/A"}
                    </Typography>
                    <Typography>
                      Total Revenue Estimates: $
                      {!isNaN(Number(totalRevenueEstimates))
                        ? Number(totalRevenueEstimates).toLocaleString("en-US")
                        : "N/A"}
                    </Typography>

                    {artist?.external_urls?.spotify ? (
                      <FollowButton>
                        <Typography>
                          <Link
                            href={artist.external_urls.spotify}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                            sx={{ color: "inherit", textDecoration: "none" }}
                          >
                            Spotify <FaExternalLinkAlt />
                          </Link>
                        </Typography>
                      </FollowButton>
                    ) : (
                      <Typography>No Spotify URL available</Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="h6" color="error">
                    No artist data found.
                  </Typography>
                )}
              </ProfileDetails>
            </ProfileContainer>

            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              display="flex"
              justifyContent="center"
            >
              <DateRangeContainer>
                <Box sx={{ mx: 2 }}>
                  {" "}
                  <Typography variant="h6">From</Typography>
                </Box>
                <DateInput
                  type="date"
                  value={startDate}
                  onFocus={(e) => e.target.showPicker()}
                  onChange={handleStartDateChange}
                  max={endDate}
                  InputProps={{
                    style: {
                      borderRadius: "50px",
                    },
                  }}
                />
                <Box sx={{ mx: 2 }}>
                  <Typography variant="h6">To</Typography>
                </Box>
                <DateInput
                  type="date"
                  value={endDate}
                  onFocus={(e) => e.target.showPicker()}
                  onChange={handleEndDateChange}
                  min={startDate}
                  InputProps={{
                    style: {
                      borderRadius: "50px",
                    },
                  }}
                />
              </DateRangeContainer>
            </Grid>

            <ChartContainer>
              <ChartTitle variant="h5">Listener's Overview</ChartTitle>
              <LineChart>
                <Line data={listenersData} options={options} />
              </LineChart>
            </ChartContainer>

            <ChartContainer>
              <ChartTitle variant="h5">Revenue Estimates</ChartTitle>
              <LineChart>
                <Bar data={data} options={options} />
              </LineChart>
            </ChartContainer>
          </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ArtistListenersGraph;
