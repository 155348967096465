import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Checkbox,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import typographyStyles from "../../../theme/Typography";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditHeading: {
    ...typographyStyles.heading,
  },
  radioContainer: {
    display: "flex",
  },
};

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(0, 168, 215)",
    color: "white",
    flex: "1 1 auto",
    padding: 8,
  },

  tableContainer: {
    overflowX: "auto",
  },

  tableheaderCell: {
    backgroundColor: "rgb(0, 168, 215)",
    color: "#fff",
    fontSize: "16px",
    padding: "4px 8px",
    fontWeight: 500,
    height: 40,
  },

  tableCell: {
    flex: "1 1 auto",
    padding: "4px 8px",
    fontSize: "14px",
  },
};

const ViewSubAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.userData;

  return (
    <DashboardLayout sectionName="Sub Admin Management" title="View Profile">
      <Paper>
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e5f6fb",
              py: 4,
              mb: 4,
              borderRadius: "15px",
            }}
          >
            <Box sx={{ textAlign: "center", gap: "10px" }}>
              <Avatar
                src={data?.image}
                sx={{ width: 120, height: 120, margin: "0 auto" }}
              />
              <Typography sx={addEditStyle.addEditLabel} mt={1}>
                {data?.userName}
              </Typography>
              <Typography sx={addEditStyle.addEditLabel} mt={1}>
                {data?.email}
              </Typography>
            </Box>
          </Box>

          <Typography sx={addEditStyle.addEditHeading} gutterBottom>
            Permissions
          </Typography>
          <TableContainer sx={PaginationTableStyle.tableContainer}>
            <Table>
              <TableHead>
                <TableRow sx={PaginationTableStyle.rowStyle}>
                  <TableCell style={PaginationTableStyle.tableheaderCell}>
                    S.No
                  </TableCell>
                  <TableCell style={PaginationTableStyle.tableheaderCell}>
                    Permission
                  </TableCell>
                  <TableCell style={PaginationTableStyle.tableheaderCell}>
                    Granted
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.permissions.map((permission, index) => (
                  <TableRow key={permission.label}>
                    <TableCell style={PaginationTableStyle.tableCell}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={PaginationTableStyle.tableCell}>
                      {permission.label}
                    </TableCell>
                    <TableCell style={PaginationTableStyle.tableCell}>
                      <Checkbox checked={permission.granted} disabled />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </DashboardLayout>
  );
};

export default ViewSubAdmin;
